import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

export default function BookingSelectLaminate() {
  const navigate = useNavigate();
  const [type, setType] = useState(1);

  const [capacity, setCapacity] = useState([]);
  const [booking_capacity, setBookingCapacity] = useState("");
  const [booking_date, setBookingDate] = useState("");
  const [limite, setLimite] = useState("");
  const [error, setError] = useState("");

  const [fab_name, setFabName] = useState(
    JSON.parse(localStorage.getItem("fab"))
  );
  const [user_name, setUserName] = useState(
    JSON.parse(localStorage.getItem("name"))
  );

  const [faburl, setFabUrl] = useState(JSON.parse(localStorage.getItem("url")));
  const [fabcode, setFabCode] = useState(
    JSON.parse(localStorage.getItem("code"))
  );
  const [qtType, setQtType] = useState(true);
  const [checked, setChecked] = useState(true);
  const [message, setMessage] = useState("");

  const handleOnChange1 = () => {};

  const handleOnChange2 = () => {};

  const getData = async () => {};

  useEffect(() => {}, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(type);
    // window.location.href = `/dev/bookings-laminate/select/${type}`;
    navigate(`/bookings-laminate/select/${type}`);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{fab_name}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Laminate</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h5 className="m-0">จองวันที่ผลิตสินค้ากลุ่มสี Laminate</h5>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            {/* <label htmlFor="name">FAB name</label> */}
                            <input
                              type="text"
                              className="form-control"
                              value={fab_name}
                              onChange={(event) =>
                                setFabName(event.target.value)
                              }
                              hidden
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="type">
                              เลือกประเภทสินค้ากลุ่มสี Laminate
                            </label>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="optradio"
                                  onChange={() => setType(1)}
                                  // checked={checked}
                                  defaultChecked
                                />
                                Precut
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="optradio"
                                  onChange={() => setType(2)}
                                />
                                Set
                              </label>
                            </div>

                            {["PM22", "SJ53", "TY98", "SW60", "IH25"].includes(
                              fabcode
                            ) && (
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name="optradio"
                                    onChange={() => setType(3)}
                                  />
                                  Prefab
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <span>
                              ทางบริษัทฯ ขอแจ้งปรับมาตรฐาน Leadtime
                              สินค้ากลุ่มประตูหน้าต่าง โดยมีรายละเอียดดังนี้
                            </span>
                            <ol>
                              <li>
                                สินค้ากลุ่ม Precut สีลามิเนต ปรับ Leadtime เป็น
                                38 วัน นับจากวันที่แบบอนุมัติ
                              </li>
                              <li>
                                สินค้ากลุ่ม Window Set สีลามิเนต ปรับ Leadtime
                                เป็น 45 วัน นับจากวันที่แบบอนุมัติ
                              </li>
                            </ol>
                            <span className="text-danger">
                              *** มีผลตั้งแต่วันที่ 4 มิ.ย. 67 เป็นต้นไป
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            {/* <label htmlFor="name">User name</label> */}
                            <input
                              type="text"
                              className="form-control"
                              value={user_name}
                              onChange={(event) =>
                                setUserName(event.target.value)
                              }
                              hidden
                            />
                          </div>
                        </div>
                        <div className="col-md-12 float-right">
                          <div className="float-right">
                            <button className="btn btn-primary">ยืนยัน</button>{" "}
                            <Link to="/bookings" className="btn btn-danger">
                              ยกเลิก
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
