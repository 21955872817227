import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import axios from "axios";
import { Constants } from "../../../config/Constants";

export default function Status() {
  const [precut, setPrecut] = useState([]);
  const [error, setError] = useState("");
  const [quotation] = useState("");
  const [status] = useState("");
  const [cancelLists, setCancelLists] = useState([]);

  const [sendDate, setSendDate] = useState(new Date());
  const [requestDate, setRequestDate] = useState(new Date());

  const role = JSON.parse(localStorage.getItem("role"));

  if (role === "user") {
    window.location.href = "/th";
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const columns = [
    {
      dataField: "fabricator_name",
      text: "Fabricator",
      sort: true,
    },
    {
      dataField: "order_receive_date",
      text: "วันส่งคำสั่งซื้อ",
      sort: true,
      formatter: (cellContent, row) => {
        return <Moment format="DD-MM-YYYY">{row.order_receive_date}</Moment>;
      },
    },
    {
      dataField: "order_type",
      text: "ประเภทงาน",
      formatter: (cellContent, row) => {
        if (row.order_type === "ตรวจแบบอย่างเดียว") {
          return (
            <h5>
              <span className="badge badge-primary">{row.order_type}</span>
            </h5>
          );
        } else {
          return <p>{row.order_type}</p>;
        }
      },
      sort: true,
    },
    {
      dataField: "qt_number",
      text: "เลขที่ใบเสนอราคา",
      formatter: (cellContent, row) => {
        if (!row.qt_number) {
          return <p>ตามไฟล์อัพโหลด</p>;
        } else {
          return <p>{row.qt_number}</p>;
        }
      },
      sort: true,
    },
    {
      dataField: "real_customer_name",
      text: "ชื่อโครงการ/ลูกค้า",
      formatter: (cellContent, row) => {
        if (!row.real_customer_name) {
          return <p>ตามไฟล์อัพโหลด</p>;
        } else {
          return <p>{row.real_customer_name}</p>;
        }
      },
      sort: true,
    },
    {
      dataField: "request_date",
      text: "วันที่ต้องการสินค้า",
      sort: true,
      formatter: (cellContent, row) => {
        if (row.order_type === "ตรวจแบบอย่างเดียว") {
          return <p className="text-center">-</p>;
        }
        if (row.request_date === "0000-00-00") {
          return <p>ตามไฟล์อัพโหลด</p>;
        } else {
          return <Moment format="DD-MM-YYYY">{row.request_date}</Moment>;
        }
      },
    },
    {
      dataField: "order_status",
      text: "สถานะงาน",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: actionButton,
      align: "center",
      headerStyle: { textAlign: "center" },
    },
  ];

  function actionButton(cell, row, rowIndex, formatExtraData) {
    //Cancel Order date range.
    const CANCEL_ORDER_DATE = moment(row.created_at).add(3, "days").toDate();
    const CURRENT_DATE = moment().toDate();
    let appDate = moment(row.approve_master_date, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const APPROVE_DATE = moment(appDate).add(3, "days").toDate();
    const linkApprove =
      row.order_status == "รอตรวจแบบ"
        ? `/bookings/cancel/normal/${row.submission_id}`
        : row.order_status == "อนุมัติเตรียมแผนการผลิต"
        ? row.approve_master_date
          ? APPROVE_DATE > CURRENT_DATE
            ? `/bookings/cancel/normal/${row.submission_id}`
            : `/bookings/cancel/special/${row.submission_id}`
          : ""
        : "";

    const cancelOrder = () => {
      alert("ต้องการยกเลิกออเดอร์ : " + row.file_name);
    };

    return (
      <>
        <div>
          <Link
            to={"/backend/status/view/" + row.filename_encryp}
            className="btn btn-info btn-sm"
          >
            <i className="fas fa-eye"></i>
          </Link>{" "}
          {linkApprove && row.order_type != "โครงการ ผลิตตามแบบ" ? (
            <Link
              to={linkApprove}
              className={
                row.order_status == "อนุมัติเตรียมแผนการผลิต"
                  ? APPROVE_DATE > CURRENT_DATE
                    ? "btn btn-warning btn-sm"
                    : "btn btn-danger btn-sm"
                  : "btn btn-warning btn-sm"
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={
                row.order_status == "อนุมัติเตรียมแผนการผลิต"
                  ? APPROVE_DATE > CURRENT_DATE
                    ? "แจ้งยกเลิก"
                    : "ร้องขอแจ้งยกเลิกแบบพิเศษ"
                  : ""
              }
            >
              <i className="fas fa-times text-white"></i>
            </Link>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  async function searchFab(key) {
    try {
      await fetch(`${Constants.url.API_URL}precut-fab-name?data=${key}`)
        .then((res) => res.json())
        .then((res) => setPrecut(res.precut));
    } catch (error) {
      setError(error);
    }
  }

  async function searchSendDate(date) {
    try {
      setSendDate(date);
      await fetch(
        `${Constants.url.API_URL}precut-send-date?data=${moment(date).format(
          "YYYY-MM-DD"
        )}`
      )
        .then((res) => res.json())
        .then((res) => setPrecut(res.precut));
    } catch (error) {
      setError(error);
    }
    //alert(moment(date).format("YYYY-MM-DD"))
  }

  async function searchRequestDate(date) {
    try {
      setRequestDate(date);
      await fetch(
        `${Constants.url.API_URL}precut-request-date?data=${moment(date).format(
          "YYYY-MM-DD"
        )}`
      )
        .then((res) => res.json())
        .then((res) => setPrecut(res.precut));
    } catch (error) {
      setError(error);
    }
  }

  async function searchStatus(key) {
    try {
      await fetch(`${Constants.url.API_URL}precut-status?data=${key}`)
        .then((res) => res.json())
        .then((res) => setPrecut(res.precut));
    } catch (error) {
      setError(error);
    }
  }

  async function searchQt(key) {
    try {
      await fetch(`${Constants.url.API_URL}precut-qutation?data=${key}`)
        .then((res) => res.json())
        .then((res) => setPrecut(res.precut));
    } catch (error) {
      setError(error);
    }
  }

  async function getData() {
    try {
      await fetch(`${Constants.url.API_URL}precut`)
        .then((res) => res.json())
        .then((res) => setPrecut(res.precut));
    } catch (error) {
      setError(error);
    }
  }

  const getCancelLists = async () => {
    try {
      await axios
        .get(`${Constants.url.API_URL}confirm-status?data=Waiting`)
        .then((res) => setCancelLists(res.data.confirm));
    } catch (e) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    getCancelLists();
  }, []);

  if (error) {
    return (
      <>
        <p>{error}</p>
      </>
    );
  }

  function addComma(input, decision = 0, defaultText = "0") {
    if (input || input === 0) {
      var parts = input.toString().replace(/[^-0-9\.]+/g, "");
      parts = parts.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      parts[0] = parts[0] ? parts[0] : "0";
      if (parts.length >= 2) {
        if (parts[1].length >= decision) {
          parts[1] = parts[1].substring(0, decision);
        } else {
          parts[1] = parts[1].padEnd(decision, "0");
        }
      } else if (decision) {
        parts.push("0");
        parts[1] = parts[1].padEnd(decision, "0");
      }

      parts = parts.slice(0, 2);
      return parts.join(".");
    }
    return defaultText;
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Status</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Status</li>
                </ol>
              </div>
            </div>
            {cancelLists && cancelLists.length > 0 ? (
              <div className="alert alert-danger alert-dismissible">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <p>
                  <i className="icon fas fa-exclamation-circle"></i>
                  พบรายการค้างค่าใช้จ่ายจำนวน {cancelLists.length} รายการ
                  เนื่องจากมีรายการรอเรียนเก็บเงินจากกรณียกเลิกหน้างานไม่ทัน
                  ดังต่อไปนี้
                </p>
                <div className="d-flex flex-column">
                  {cancelLists.map((o, i) => (
                    <p>
                      {i + 1}. เลขที่ใบเสนอราคา : {o.quatation_no} จำนวนเงิน{" "}
                      {addComma(o.cost_for_cancel)} บาท [
                      <Link to={`/bookings/cancel/confirm/${o.submission_id}`}>
                        คลิกที่นี่
                      </Link>
                      ]
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h5 className="m-0">จัดการสถานะงาน</h5>
                  </div>
                  <div className="card-body">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="">Fabricator</label>
                              <br></br>
                              <input
                                name={quotation}
                                type="text"
                                className="form-control"
                                placeholder="Fabricator"
                                onChange={(event) =>
                                  searchFab(event.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="">วันส่งคำสั่งซื้อ</label>
                              <br></br>
                              <DatePicker
                                className="form-control"
                                selected={sendDate}
                                onChange={searchSendDate}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <label htmlFor="">วันที่ต้องการสินค้า</label>
                              <br></br>
                              <DatePicker
                                className="form-control"
                                selected={requestDate}
                                onChange={searchRequestDate}
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="">เลขที่ใบเสนอราคา</label>
                              <br></br>
                              <input
                                name={quotation}
                                type="text"
                                className="form-control"
                                placeholder="เลขที่ใบเสนอราคา"
                                onChange={(event) =>
                                  searchQt(event.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label htmlFor="">สถานะงาน</label>
                              <br></br>
                              <select
                                class="form-control"
                                id="sel1"
                                name={status}
                                onChange={(event) =>
                                  searchStatus(event.target.value)
                                }
                              >
                                <option value="">เลือกสถานะสินค้า</option>
                                <option value="รับข้อมูลเข้าระบบ">
                                  รับข้อมูลเข้าระบบ
                                </option>
                                <option value="รอตรวจแบบ">รอตรวจแบบ</option>
                                <option value="อนุมัติเตรียมแผนการผลิต">
                                  อนุมัติเตรียมแผนการผลิต
                                </option>
                                <option value="อนุมัติการตรวจแบบแล้ว">
                                  อนุมัติการตรวจแบบแล้ว
                                </option>
                                <option value="ไม่ได้รับการอนุมัติ">
                                  ไม่ได้รับการอนุมัติ
                                </option>
                                <option value="ปิดเคส">ปิดเคส</option>
                                <option value="ยกเลิก">ยกเลิก</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          keyField="id"
                          data={precut}
                          columns={columns}
                          pagination={paginationFactory(options)}
                          noDataIndication="ไม่พบข้อมูล"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
