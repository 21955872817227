import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Stepper, Step } from "react-form-stepper";
import moment from "moment";
import "moment-timezone";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Constants } from "../../../config/Constants";
import { ErrorMessage } from "@hookform/error-message";

interface FormInputs {
  status_get_order: string;
  reason: string;
}

const BookingCancel = () => {
  const { page, submission_id, id } = useParams();
  const navigate = useNavigate();
  const [precut, setPrecut] = useState({});
  const [realcustomer, setRealCustomer] = useState(true);
  const [amount, setAmount] = useState(true);
  const [qunmber, setQnumber] = useState(false);
  const [group, setGroup] = useState(false);
  const [color, setColor] = useState(false);
  const [series, setSeries] = useState(false);
  const [type, setType] = useState(false);
  const [price, setPrice] = useState("");
  const [cancelDetail, setCancelDetail] = useState([]);
  const [reason, setReason] = useState(null);
  const [status_get_order, setStatusGetOrder] = useState(null);
  const [error, setError] = useState("");
  const [requestdate, setRequestDate] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);
  const [user_name, setUserName] = useState(
    JSON.parse(localStorage.getItem("name"))
  );

  const [role, setRole] = useState(JSON.parse(localStorage.getItem("role")));

  const [fab_name, setFabName] = useState(
    JSON.parse(localStorage.getItem("fab"))
  );

  const [btn, seBtn] = useState(false);

  function addComma(input, decision = 0, defaultText = "0") {
    if (input || input === 0) {
      var parts = input.toString().replace(/[^-0-9\.]+/g, "");
      parts = parts.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      parts[0] = parts[0] ? parts[0] : "0";
      if (parts.length >= 2) {
        if (parts[1].length >= decision) {
          parts[1] = parts[1].substring(0, decision);
        } else {
          parts[1] = parts[1].padEnd(decision, "0");
        }
      } else if (decision) {
        parts.push("0");
        parts[1] = parts[1].padEnd(decision, "0");
      }

      parts = parts.slice(0, 2);
      return parts.join(".");
    }
    return defaultText;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function getData() {
    try {
      //setPrecut(res.data.precut[0])
      await axios
        .get(`${Constants.url.API_URL}precut-submission?data=${submission_id}`)
        .then((res) => setPrecut(res.data.precut[0]));
    } catch (error) {
      setError(error);
    }
  }

  async function getCancelPrecut() {
    try {
      //res.data.confirm[0].cost_for_cancel
      await axios
        .get(`${Constants.url.API_URL}confirm-submission?data=${submission_id}`)
        .then((res) => {
          setPrice(res.data.confirm[0].cost_for_cancel);
          setCancelDetail(res.data.confirm[0]);
        });
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    getData();
    hideDate();
    hideItems();
    getCancelPrecut();
  }, [
    precut.order_type,
    precut.product_type,
    precut.product_group,
    precut.product_color,
    precut.product_series,
    precut.request_date,
    precut.recieve_address,
    precut.real_customer_name,
    precut.amount,
    precut.order_status,
    precut.requestdate,
  ]);

  function hideItems() {
    if (precut.product_type) {
      setType(true);
    }
    if (precut.product_group) {
      setGroup(true);
    }
    if (precut.product_color) {
      setColor(true);
    }
    if (precut.product_series) {
      setSeries(true);
    }
    if (precut.qt_number) {
      setQnumber(true);
    }
    if (precut.order_type === "ตรวจแบบอย่างเดียว") {
      setRequestDate(false);
    }
    if (precut.amount === 0) {
      setAmount(false);
    }
    return;
  }

  function hideDate() {
    if (precut.order_type == "โครงการ ผลิตตามแบบ") {
      setRequestDate(false);
      setRealCustomer(false);
    }
    return;
  }

  const onSubmit = (data: FormInputs) => {
    seBtn(true);
    const formData = {
      submission_id: submission_id,
    };

    if (["normal", "special"].includes(page)) {
      formData.req_cancel_by = user_name;
      formData.req_cancel_reason = data.reason;

      if (page == "normal") {
        formData.order_status = precut.order_status;
      }

      if (page == "special") {
        formData.status_get_order = data.status_get_order;
      }
    } else {
      formData.confirm_cancel_by = user_name;
      formData.confirm_cancel_remark = data.reason;
      formData.file_name = cancelDetail.file_name;
      formData.fab_name = cancelDetail.fab_name;
      formData.quatation_no = precut.qt_number;
      formData.cost_for_cancel = cancelDetail.cost_for_cancel;
      formData.status_req_cancel = cancelDetail.status_req_cancel;
      formData.confirm_url = cancelDetail.confirm_url;
      formData.created_at = cancelDetail.created_at;
      formData.updated_at = cancelDetail.updated_at;
      formData.filename_encryp = cancelDetail.filename_encryp;
      formData.sale_name = cancelDetail.sale_name;
      formData.status_get_order = cancelDetail.status_get_order;
    }

    const update = {
      submission_id: submission_id,
      order_status: "กำลังดำเนินการยกเลิก",
      req_cancel_reason: data.reason,
      req_cancel_by: user_name,
      svt: "SBCI)sm!jdS^UEK8v!",
    };

    Swal.fire({
      title:
        "คุณต้องการยืนยันการยกเลิกคำสั่งซื้อเลขใบเสนอราคา " +
        precut.qt_number +
        " ใช่หรือไม่ ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ยืนยัน",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          };

          await axios
            .post(
              `${Constants.url.API_URL}precut-update-submission`,
              JSON.stringify(update),
              { headers: headers }
            )
            .then(async (res) => {
              if (res.data.status == 200) {
                const headers = {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                };

                let api =
                page == "normal"
                  ? Constants.url.POWER_AUTO.normal
                  : page == "special"
                  ? Constants.url.POWER_AUTO.special
                  : Constants.url.POWER_AUTO.confirm;

                  
                await axios
                  .post(api, JSON.stringify(formData), { headers: headers })
                  .then((res) => {
                    if (res.data.status == 200) {
                      setTimeout(() => {
                        seBtn(false);
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title:
                            page == "confirm"
                              ? "ยืนยันค่าใช้จ่ายเรียบร้อย"
                              : "ส่งคำร้องยกเลิกคำสั่งผลิตเรียบร้อย",
                          showConfirmButton: false,
                          timer: 1500,
                        });

                        page == "confirm"
                          ? navigate("/bookings")
                          : role == "user"
                          ? navigate("/booking-status")
                          : navigate("/backend/status");
                      }, 20000);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    seBtn(false);
                    if (error.code) {
                      Swal.fire({
                        icon: "error",
                        title: "Something wrong !",
                        text: error.message,
                      });
                    }
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              seBtn(false);
              if (error.code) {
                Swal.fire({
                  icon: "error",
                  title: "Something wrong !",
                  text: error.message,
                });
              }
            });
        } catch (error) {
          console.log(error);
          seBtn(false);
          if (error.code) {
            Swal.fire({
              icon: "error",
              title: "Something wrong !",
              text: error.message,
            });
          }
        }
      } else {
        seBtn(false);
      }
    });
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{cancelDetail.fab_name}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">View</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h5 className="m-0">
                      {page == "normal"
                        ? "ฟอร์มแจ้งยกเลิก"
                        : page == "special"
                        ? "ฟอร์มแจ้งยกเลิกพิเศษ"
                        : ""}
                      เลขที่ใบเสนอราคา : <strong>{precut.qt_number}</strong>{" "}
                      {price ? (
                        <>
                          {page == "confirm" ? (
                            <>
                              มีค่าใช้จ่ายเกิดขึ้น {" "}
                              <strong>{addComma(price)} บาท</strong>{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {page == "confirm"
                        ? " กรุณากดปุ่มรับทราบ"
                        : page == "special"
                        ? " (อาจมีเรียกเก็บค่าใช้จ่าย)"
                        : ""}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <table className="table table-bordered mt-4">
                        <tr>
                          <td width={"50%"}>วันส่งคำสั่งซื้อ</td>
                          <td>
                            {precut.order_receive_date
                              ? moment(
                                  new Date(precut.order_receive_date)
                                ).format("DD-MM-YYYY")
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>ประเภทงาน</td>
                          <td>{precut.order_type}</td>
                        </tr>
                        {realcustomer ? (
                          <tr>
                            <td>ชื่อโครงการ/ลูกค้า</td>
                            <td>{precut.real_customer_name}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {amount ? (
                          <>
                            <tr>
                              <td>จำนวนชุดทั้งหมด</td>
                              <td>{precut.amount}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {qunmber ? (
                          <tr>
                            <td>เลขที่ใบเสนอราคา</td>
                            <td>{precut.qt_number}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {type ? (
                          <tr>
                            <td>ประเภทการผลิต</td>
                            <td>{precut.product_type}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {group ? (
                          <tr>
                            <td>ประเภทสินค้า</td>
                            <td>{precut.product_group}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {color ? (
                          <tr>
                            <td>สีของสินค้า</td>
                            <td>{precut.product_color}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {series ? (
                          <tr>
                            <td>รุ่นสินค้า</td>
                            <td>{precut.product_series}</td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {page == "confirm" && cancelDetail.status_get_order ? (
                          <tr>
                            <td>แจ้งความประสงค์</td>
                            <td>{cancelDetail.status_get_order}</td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {page == "confirm" && price ? (
                          <tr>
                            <td>ค่าใช้จ่ายทั้งหมด</td>
                            <td>{addComma(price)} บาท</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {cancelDetail.confirm_url && page == "confirm" ? (
                          <tr>
                            <td>ไฟล์ BOM จากทีมขาย</td>
                            <td>
                              {" "}
                              <Link
                                to={cancelDetail.confirm_url}
                                className="btn btn-primary"
                              >
                                ดาวน์โหลด
                              </Link>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </table>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {page == "special" ? (
                          <>
                            <div className="col-md-12 mb-1">
                              <span>
                                <strong>
                                  กรณียกเลิกคำสั่งผลิตไม่ทันและมีการดำเนินการผลิตแล้วท่านต้องการรับของหรือไม่?
                                </strong>
                              </span>
                              <span className="text-danger">*</span>
                            </div>
                            <div className="col-md-12">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio2"
                                  value="รับของกลับ"
                                  {...register("status_get_order", {
                                    required:
                                      "กรุณาเลือกต้องการรับสินค้ากลับหรือไม่ ?",
                                  })}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio2"
                                >
                                  รับของกลับ
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio3"
                                  value="ไม่รับของกลับ"
                                  {...register("status_get_order", {
                                    required:
                                      "กรุณาเลือกต้องการรับสินค้ากลับหรือไม่ ?",
                                  })}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio3"
                                >
                                  ไม่รับของกลับ
                                </label>
                              </div>
                              {/* <ErrorMessage
                                errors={errors}
                                name="status_get_order"
                                class="text-danger"
                              /> */}
                              <ErrorMessage
                                errors={errors}
                                name="status_get_order"
                                className="text-danger"
                                render={(o, type) => {
                                  return o.message ? (
                                    <p key={o.message} className="text-danger">
                                      {o.message}
                                    </p>
                                  ) : null;
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="col-md-12 mt-3">
                          <div className="form-group">
                            <label htmlFor="name">
                              {page == "confirm"
                                ? "หมายเหตุ"
                                : page == "normal"
                                ? "เหตุผลร้องขอการยกเลิกคำสั่งผลิต"
                                : "เหตุผลที่ยกเลิกคำสั่งผลิต"}
                            </label>
                            {page != "confirm" ? (
                              <span className="text-danger">*</span>
                            ) : (
                              ""
                            )}
                            <textarea
                              class="form-control"
                              placeholder={
                                page == "confirm"
                                  ? "หมายเหตุ"
                                  : "เหตุผลที่ยกเลิกคำสั่งผลิต"
                              }
                              rows="3"
                              value={reason}
                              {...register(
                                "reason",
                                page == "confirm"
                                  ? { required: false }
                                  : {
                                      required:
                                        page == "normal"
                                          ? "กรุณาระบุเหตุผลร้องขอการยกเลิกคำสั่งผลิต"
                                          : "กรุณาระบุเหตุผลที่ยกเลิกคำสั่งผลิต",
                                    }
                              )}
                            ></textarea>
                            <ErrorMessage
                              errors={errors}
                              name="reason"
                              render={(o, type) => {
                                return o.message ? (
                                  <p key={o.message} className="text-danger">
                                    {o.message}
                                  </p>
                                ) : null;
                              }}
                            />
                          </div>
                        </div>
                        {["special", "confirm"].includes(page) ? (
                          <div
                            className="col-md-12"
                            style={{ backgroundColor: "yellow" }}
                          >
                            <h5 className="text-danger">
                              {page == "special" ? (
                                <>
                                  *หมายเหตุ อาจมีการ<u>เรียกเก็บค่าใช้จ่าย</u>
                                  เกิดขึ้น
                                </>
                              ) : (
                                <>
                                  *หมายเหตุ
                                  หากท่านยืนยันรับทราบทางทีมจะดำเนินเรียกเก็บค่าใช้จ่าย
                                </>
                              )}
                            </h5>
                            {page == "special" ? (
                              <h5 className="text-danger">
                                หากมีการดำเนินการผลิตบางส่วนเกิดแล้ว
                              </h5>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-12">
                          <div className="d-flex justify-content-center">
                            {btn ? (
                              <button
                                class="btn btn-primary mt-2"
                                type="button"
                                disabled
                                style={{ width: "350px" }}
                              >
                                <span
                                  class="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                โหลด...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary mt-2"
                                style={{ width: "350px" }}
                              >
                                {page == "confirm" ? "รับทราบ" : "ยืนยัน"}
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BookingCancel;
