import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Stepper, Step } from "react-form-stepper";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import { Constants } from "../../../config/Constants";

const ViewCancel = () => {
  const { id, submission_id, page } = useParams();
  const navigate = useNavigate();
  const [precut, setPrecut] = useState({});
  const [error, setError] = useState("");
  const [cancelDetail, setCancelDetail] = useState({});
  const [realcustomer, setRealCustomer] = useState(true);
  const [amount, setAmount] = useState(true);
  const [qunmber, setQnumber] = useState(false);
  const [group, setGroup] = useState(false);
  const [color, setColor] = useState(false);
  const [series, setSeries] = useState(false);
  const [type, setType] = useState(false);
  const [price, setPrice] = useState("");
  const [requestdate, setRequestDate] = useState(true);

  async function getCancelDetail() {
    try {
      //setPrecut(res.confirm[0])setCancelDetail(res.confirm[0])
      await fetch(
        `${Constants.url.API_URL}confirm-submission?data=${submission_id}`
      )
        .then((res) => res.json())
        .then((res) => setCancelDetail(res.confirm[0]));
    } catch (error) {
      setError(error);
    }
  }

  const genLink = (tag) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(tag, "text/html");
    const linkElement = doc.querySelector("a");
    let link = linkElement ? linkElement.getAttribute("href").toString() : "";
    return (
      <>
        {link ? (
          <>
            <tr>
              <td>ไฟล์ BOM จากทีมขาย</td>
              <td>
                <Link to={link} className="btn btn-primary">
                  ดาวน์โหลด
                </Link>
              </td>
            </tr>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  useEffect(() => {
    getData();
    hideDate();
    hideItems();
    getCancelDetail();
  }, [
    precut.order_type,
    precut.product_type,
    precut.product_group,
    precut.product_color,
    precut.product_series,
    precut.request_date,
    precut.recieve_address,
    precut.real_customer_name,
    precut.amount,
    precut.order_status,
    precut.requestdate,
  ]);

  function hideItems() {
    if (precut.product_type) {
      setType(true);
    }
    if (precut.product_group) {
      setGroup(true);
    }
    if (precut.product_color) {
      setColor(true);
    }
    if (precut.product_series) {
      setSeries(true);
    }
    if (precut.qt_number) {
      setQnumber(true);
    }
    if (precut.order_type === "ตรวจแบบอย่างเดียว") {
      setRequestDate(false);
    }
    if (precut.amount === 0) {
      setAmount(false);
    }
    return;
  }

  function hideDate() {
    if (precut.order_type == "โครงการ ผลิตตามแบบ") {
      setRequestDate(false);
      setRealCustomer(false);
    }
    return;
  }

  function addComma(input, decision = 0, defaultText = "0") {
    if (input || input === 0) {
      var parts = input.toString().replace(/[^-0-9\.]+/g, "");
      parts = parts.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      parts[0] = parts[0] ? parts[0] : "0";
      if (parts.length >= 2) {
        if (parts[1].length >= decision) {
          parts[1] = parts[1].substring(0, decision);
        } else {
          parts[1] = parts[1].padEnd(decision, "0");
        }
      } else if (decision) {
        parts.push("0");
        parts[1] = parts[1].padEnd(decision, "0");
      }

      parts = parts.slice(0, 2);
      return parts.join(".");
    }
    return defaultText;
  }

  async function getData() {
    try {
      await fetch(
        `${Constants.url.API_URL}precut-submission?data=${submission_id}`
      )
        .then((res) => res.json())
        .then((res) => setPrecut(res.precut[0]));
    } catch (error) {
      setError(error);
    }
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  รายการค้างจ่าย : {cancelDetail.fab_name}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">View</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h5 className="m-0">
                      เลขใบเสนอราคา : {precut.qt_number}{" "}
                      {price ? <>จำนวนค่าใช้จ่าย {addComma(price)} บาท</> : ""}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <table className="table table-bordered mt-4">
                        {realcustomer ? (
                          <tr>
                            <td>ชื่อโครงการ/ลูกค้า</td>
                            <td>{precut.real_customer_name}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {amount ? (
                          <>
                            <tr>
                              <td>จำนวนชุดทั้งหมด</td>
                              <td>{precut.amount} ชุด</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {qunmber ? (
                          <tr>
                            <td>เลขที่ใบเสนอราคา</td>
                            <td>{precut.qt_number}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {type ? (
                          <tr>
                            <td>ประเภทการผลิต</td>
                            <td>{precut.product_type}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {group ? (
                          <tr>
                            <td>ประเภทสินค้า</td>
                            <td>{precut.product_group}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {color ? (
                          <tr>
                            <td>สีของสินค้า</td>
                            <td>{precut.product_color}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {series ? (
                          <tr>
                            <td>รุ่นสินค้า</td>
                            <td>{precut.product_series}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {page == "confirm" && price ? (
                          <tr>
                            <td>ค่าใช้จ่ายทั้งหมด</td>
                            <td>{addComma(price)} บาท</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td width={"50%"}>วันที่ร้องขอยกเลิกคำสั่งผลิต</td>
                          <td>
                            {cancelDetail.created_at
                              ? moment(
                                  new Date(cancelDetail.created_at)
                                ).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                        </tr>
                        {cancelDetail.cost_for_cancel ? (
                          <>
                            <tr>
                              <td>ค่าใช้จ่าย</td>
                              <td>
                                {addComma(cancelDetail.cost_for_cancel)} บาท
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {cancelDetail.sale_name ? (
                          <>
                            <tr>
                              <td>ผู้แทนขาย</td>
                              <td>{cancelDetail.sale_name}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {cancelDetail.status_get_order ? (
                          <>
                            <tr>
                              <td>แจ้งความประสงค์</td>
                              <td>{cancelDetail.status_get_order}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>สถานะการยกเลิก</td>
                          <td>
                            {cancelDetail.quatation_no == "Waiting"
                              ? "รอการยืนยัน"
                              : "ยืนยันเรียบร้อย"}
                          </td>
                        </tr>

                        {genLink(cancelDetail.confirm_url)}
                      </table>
                      <div className="col-md-12 float-right">
                        <div className="float-right">
                          <Link
                            to={"/backend/cancel"}
                            className="btn btn-danger"
                          >
                            Cancel
                          </Link>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewCancel;
