import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Constants } from "../../../config/Constants";
import axios from "axios";

export default function CancelList() {
  const [precut, setPrecut] = useState([]);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("Waiting");
  const [lists, setLists] = useState("Waiting");
  const role = JSON.parse(localStorage.getItem("role"));
  const [feb] = useState("");
  const [sale] = useState("");

  if (role === "user") {
    window.location.href = "/th";
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cellContent, row, index) => {
        return <div>{index + 1}</div>;
      },
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "fab_name",
      text: "Fabricator",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "วันส่งยกเลิกคำสั่งผลิต",
      sort: true,
      formatter: (cellContent, row) => {
        return <Moment format="DD-MM-YYYY">{row.created_at}</Moment>;
      },
    },
    {
      dataField: "quatation_no",
      text: "เลขใบเสนอราคา",
      formatter: (cellContent, row) => {
        return <p>{row.quatation_no}</p>;
      },
      sort: true,
    },
    {
      dataField: "cost_for_cancel",
      text: "ค่าใช้จ่าย (บาท)",
      sort: true,
      formatter: (cellContent, row) => {
        return row.cost_for_cancel ? (
          <p className="text-center">{addComma(row.cost_for_cancel)}</p>
        ) : (
          <p className="text-center">-</p>
        );
      },
    },
    {
      dataField: "status_req_cancel",
      text: "สถานะ",
      sort: true,
      formatter: (cellContent, row) => {
        return row.status_req_cancel === "Waiting" ? (
          <span class="badge bg-warning text-dark text-center">
            รอการยืนยัน
          </span>
        ) : (
          <span class="badge bg-success text-center">ยืนยันเรียบร้อย</span>
        );
      },
    },
    {
      dataField: "sale_name",
      text: "ผู้แทนขาย",
      sort: true,
      formatter: (cellContent, row) => {
        return row.sale_name ? (
          <p className="text-center">{row.sale_name}</p>
        ) : (
          <p className="text-center">-</p>
        );
      },
    },
    {
      dataField: "status_get_order",
      text: "แจ้งความประสงค์",
      sort: true,
      formatter: (cellContent, row) => {
        return row.status_get_order ? (
          row.status_get_order == "รับสินค้า" ? (
            <span class="badge bg-primary text-center">
              {row.status_get_order}
            </span>
          ) : (
            <span class="badge bg-danger text-center">
              {row.status_get_order}
            </span>
          )
        ) : (
          <p className="text-center">-</p>
        );
      },
    },
    {
      dataField: "submission_id",
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          <div>
            <Link
              to={"/backend/cancel/view/" + row.submission_id}
              className="btn btn-info"
            >
              <i className="fas fa-eye"></i>
            </Link>{" "}
          </div>
        );
      },
      align: "center",
      headerStyle: { textAlign: "center" },
    },
  ];

  async function searchStatus(key) {
    try {
      setStatus(key);
      let api = key
        ? `${Constants.url.API_URL}confirm-status?data=${key}`
        : `${Constants.url.API_URL}confirms`;
      await fetch(api)
        .then((res) => res.json())
        .then((res) =>
          key ? setPrecut(res.confirm) : setPrecut(res.confirms)
        );
    } catch (error) {
      setError(error);
    }
  }

  async function searchFab(key) {
    try {
      let name = key;
      await axios
        .get(`${Constants.url.API_URL}confirm-fab?data=${name}`)
        .then((res) => setPrecut(res.data.confirm));
    } catch (error) {
      setError(error);
    }
  }

  async function searchSale(key) {
    try {
      let name = key;

      await axios
        .get(`${Constants.url.API_URL}confirm-sale?data=${name}`)
        .then((res) => setPrecut(res.data.confirm));
    } catch (error) {
      setError(error);
    }
  }

  function addComma(input, decision = 0, defaultText = "0") {
    if (input || input === 0) {
      var parts = input.toString().replace(/[^-0-9\.]+/g, "");
      parts = parts.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      parts[0] = parts[0] ? parts[0] : "0";
      if (parts.length >= 2) {
        if (parts[1].length >= decision) {
          parts[1] = parts[1].substring(0, decision);
        } else {
          parts[1] = parts[1].padEnd(decision, "0");
        }
      } else if (decision) {
        parts.push("0");
        parts[1] = parts[1].padEnd(decision, "0");
      }

      parts = parts.slice(0, 2);
      return parts.join(".");
    }
    return defaultText;
  }

  async function getData() {
    //setPrecut(res.precut)
    try {
      await fetch(`${Constants.url.API_URL}confirm-status?data=Waiting`)
        .then((res) => res.json())
        .then((res) => setPrecut(res.confirm));
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    getData();
    // getFebAndSalse();
  }, []);

  if (error) {
    return (
      <>
        <p>{error}</p>
      </>
    );
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Cancel Lists</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Cancel Lists</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h5 className="m-0">
                      รายการลูกค้าค้างจ่าย
                      {status != ""
                        ? status == "Waiting"
                          ? "รอการยืนยัน"
                          : "ยืนยันเรียบร้อย"
                        : "ทั้งหมด"}{" "}
                      จำนวน {precut.length} รายการ
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">Fabricator</label>
                              <br></br>
                              <input
                                name={feb}
                                type="text"
                                className="form-control"
                                placeholder="Fabricator"
                                onChange={(event) =>
                                  searchFab(event.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">ผู้แทนขาย</label>
                              <br></br>
                              <input
                                name={sale}
                                type="text"
                                className="form-control"
                                placeholder="ผู้แทนขาย"
                                onChange={(event) =>
                                  searchSale(event.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">สถานะงาน</label>
                              <br></br>
                              <select
                                class="form-control"
                                id="sel1"
                                name={status}
                                onChange={(event) =>
                                  searchStatus(event.target.value)
                                }
                                defaultValue={status}
                              >
                                <option value="">เลือกสถานะสินค้า</option>
                                <option value="Waiting">รอการยืนยัน</option>
                                <option value="Confirmed">
                                  ยืนยันเรียบร้อย
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          keyField="id"
                          data={precut}
                          columns={columns}
                          pagination={paginationFactory(options)}
                          noDataIndication="ไม่พบข้อมูล"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
