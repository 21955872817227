import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Stepper, Step } from "react-form-stepper";
import moment from "moment";
import "moment-timezone";
import { Constants } from "../../../config/Constants";

export default function BookingView() {
  const { id } = useParams();

  const [precut, setPrecut] = useState({});

  const [error, setError] = useState("");

  const [reject, setReject] = useState(false);
  const [reason, setReason] = useState(false);
  const [request, setRequest] = useState(false);

  const [activeCheck_1, setActiveCheck_1] = useState("");
  const [activeCheck_2, setActiveCheck_2] = useState("");
  const [activeCheck_3, setActiveCheck_3] = useState("");
  const [activeCheck_4, setActiveCheck_4] = useState("");

  const [timelineForm_1, setTimelineForm_1] = useState(false);
  const [timelineForm_2, setTimelineForm_2] = useState(false);
  const [timelineForm_3, setTimelineForm_3] = useState(false);
  const [timelineForm_4, setTimelineForm_4] = useState(false);

  const [qunmber, setQnumber] = useState(false);
  const [realcustomer, setRealCustomer] = useState(true);
  const [landNamber, SetLandNumber] = useState(false);
  const [homeDesign, SetHomeDesign] = useState(false);
  const [orderUnit, SetOrderUnit] = useState(false);
  const [orderSqm, SetOrderSqm] = useState(false);
  const [quotation, SetQuotation] = useState(false);
  const [orderPerson, SetOrderPerson] = useState(false);
  const [download, setDownload] = useState(false);
  const [type, setType] = useState(false);
  const [group, setGroup] = useState(false);
  const [color, setColor] = useState(false);
  const [series, setSeries] = useState(false);
  const [requestdate, setRequestDate] = useState(true);
  const [address, setAddress] = useState(false);
  const [prefsuitno, setPrefSuitNo] = useState(false);
  const [planningdate, setPlanningDate] = useState(false);
  const [expectdate, setExpectDate] = useState(false);
  const [completedate, setCompleteDate] = useState(false);
  const [amount, setAmount] = useState(true);
  const [cancelDetail, setCancelDetail] = useState({});
  const [fab_name, setFabName] = useState(
    JSON.parse(localStorage.getItem("fab"))
  );

  async function getData() {
    try {
      //setPrecut(res.precut)
      await fetch(`${Constants.url.API_URL}precut-status-view/${id}`)
        .then((res) => res.json())
        .then((res) => setPrecut(res.precut));
    } catch (error) {
      setError(error);
    }
  }

  // async function getCancelPrecut() {
  //   try {
  //     //res.data.confirm[0].cost_for_cancel
  //     await axios
  //       .get(`${Constants.url.API_URL}confirm-submission?data=${submission_id}`)
  //       .then((res) => {
  //         setCancelDetail(res.data.confirm[0]);
  //       });
  //   } catch (error) {
  //     setError(error);
  //   }
  // }

  function checkApproveDate(approveDate) {
    const CURRENT_DATE = moment().toDate();
    let appDate = moment(approveDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    const APPROVE_DATE = moment(appDate).add(3, "days").toDate();
    const linkApprove =
      precut.order_status == "รอตรวจแบบ"
        ? `/bookings/cancel/normal/${precut.submission_id}`
        : precut.order_status == "อนุมัติเตรียมแผนการผลิต"
        ? approveDate
          ? APPROVE_DATE > CURRENT_DATE
            ? `/bookings/cancel/normal/${precut.submission_id}`
            : `/bookings/cancel/special/${precut.submission_id}`
          : ""
        : "";

    return (
      <>
        {linkApprove ? (
          <Link to={linkApprove} className="btn btn-danger">
            ยกเลิกคำสั่งผลิต
          </Link>
        ) : (
          false
        )}
      </>
    );
  }

  useEffect(() => {
    hideDate();
    getData();
    hideItems();
  }, [
    precut.reject_comment,
    precut.reason_npi_cancel,
    precut.text_request_cancel,
    precut.land_Number,
    precut.homeDesign_Name,
    precut.order_type,
    precut.order_Unit,
    precut.order_Sqm,
    precut.keep_Quotation,
    precut.order_Person,
    precut.product_type,
    precut.product_group,
    precut.product_color,
    precut.product_series,
    precut.request_date,
    precut.recieve_address,
    precut.real_customer_name,
    precut.download,
    precut.prefsuiteNo,
    precut.order_planning_date,
    precut.order_expect_date,
    precut.order_complete_date,
    precut.requestdate,
    precut.amount,
    precut.order_status,
    precut.req_cancel_by,
    precut.req_cancel_time,
    precut.req_cancel_reason,
    precut.req_cancel_remark
  ]);

  function hideItems() {
    if (precut.land_Number) {
      SetLandNumber(true);
    }
    if (precut.homeDesign_Name) {
      SetHomeDesign(true);
    }
    if (precut.order_Unit) {
      SetOrderUnit(true);
    }
    if (precut.order_Sqm) {
      SetOrderSqm(true);
    }
    if (precut.keep_Quotation) {
      SetQuotation(true);
    }
    if (precut.order_Person) {
      SetOrderPerson(true);
    }
    if (precut.product_type) {
      setType(true);
    }
    if (precut.product_group) {
      setGroup(true);
    }
    if (precut.product_color) {
      setColor(true);
    }
    if (precut.product_series) {
      setSeries(true);
    }
    if (precut.recieve_address) {
      setAddress(true);
    }
    if (precut.qt_number) {
      setQnumber(true);
    }
    if (precut.reason_npi_cancel) {
      setReason(true);
    }
    if (precut.reject_comment) {
      setReject(true);
    }
    if (precut.text_request_cancel) {
      setRequest(true);
    }
    if (precut.prefsuiteNo) {
      setPrefSuitNo(true);
    }
    if (precut.order_planning_date) {
      setPlanningDate(true);
    }
    if (precut.order_expect_date) {
      setExpectDate(true);
    }
    if (precut.order_complete_date) {
      setCompleteDate(true);
    }
    if (precut.order_type === "ตรวจแบบอย่างเดียว") {
      setRequestDate(false);
    }
    if (precut.amount === 0) {
      setAmount(false);
    }

    if (precut.order_status === "รับข้อมูลเข้าระบบ") {
      setTimelineForm_1(true) || setActiveCheck_1(0);
    } else if (precut.order_status === "รอตรวจแบบ") {
      setTimelineForm_1(true) || setActiveCheck_1(1);
    } else if (precut.order_status === "ไม่ได้รับการอนุมัติ") {
      setTimelineForm_2(true) || setActiveCheck_2(2);
    } else if (
      precut.order_status === "อนุมัติเตรียมแผนการผลิต" &&
      precut.order_complete_date === null
    ) {
      setTimelineForm_1(true) || setActiveCheck_1(4);
    } else if (precut.order_status === "อนุมัติการตรวจแบบแล้ว") {
      setTimelineForm_3(true) || setActiveCheck_3(2);
    } else if (
      precut.order_status === "อนุมัติเตรียมแผนการผลิต" &&
      precut.order_complete_date != null
    ) {
      setTimelineForm_4(true) || setActiveCheck_4(4);
    }

    return;
  }

  const EXPIRED_DATE = moment(precut.created_at).add(30, "days").toDate();
  const CURRENT_DATE = moment().toDate();

  function hideDate() {
    if (precut.order_type == "โครงการ ผลิตตามแบบ") {
      setRequestDate(false);
      setRealCustomer(false);
    }
    if (CURRENT_DATE < EXPIRED_DATE && precut.download != null) {
      setDownload(true);
    }
    return;
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{fab_name}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">View</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h5 className="m-0">รายละเอียดสถานะงาน</h5>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      {/* Start Timeline   */}
                      {timelineForm_1 ? (
                        <div className="card">
                          <div className="card-body">
                            <Stepper
                              style={{
                                fontFamily: "Mitr",
                              }}
                              activeStep={activeCheck_1}
                              styleConfig={{
                                activeBgColor: "#2b7cff",
                                inactiveTextColor: "#fff",
                                completedBgColor: "#2b7cff",
                                //size: "2.5em",
                              }}
                            >
                              <Step
                                label="รับคำสั่งซื้อ"
                                children={<i className="far fa-calendar"></i>}
                              />
                              <Step
                                label="ตรวจสอบแบบ"
                                children={
                                  <i className="fas fa-clipboard-check"></i>
                                }
                              />
                              <Step
                                label="อนุมัติแบบ"
                                children={<i className="fas fa-user-check"></i>}
                              />
                              <Step
                                label="ลงแผนการผลิต"
                                children={<i className="fas fa-users-cog"></i>}
                              />
                            </Stepper>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {timelineForm_2 ? (
                        <div className="card">
                          <div className="card-body">
                            <Stepper
                              style={{
                                fontFamily: "Mitr",
                              }}
                              activeStep={activeCheck_2}
                              styleConfig={{
                                activeBgColor: "red",
                                inactiveTextColor: "#fff",
                                completedBgColor: "red",
                                //size: "2.5em",
                              }}
                            >
                              <Step
                                label="รับคำสั่งซื้อ"
                                children={<i className="far fa-calendar"></i>}
                              />
                              <Step
                                label="ตรวจสอบแบบ"
                                children={
                                  <i className="fas fa-clipboard-check"></i>
                                }
                              />
                              <Step
                                label="ไม่อนุมัติแบบ"
                                children={<i className="fas fa-user-times"></i>}
                              />
                              <Step
                                label="ลงแผนการผลิต"
                                children={<i className="fas fa-users-cog"></i>}
                              />
                            </Stepper>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {timelineForm_3 ? (
                        <div className="card">
                          <div className="card-body">
                            <Stepper
                              style={{
                                fontFamily: "Mitr",
                              }}
                              activeStep={activeCheck_3}
                              styleConfig={{
                                activeBgColor: "#2b7cff",
                                inactiveTextColor: "#fff",
                                completedBgColor: "#2b7cff",
                                //size: "2.5em",
                              }}
                            >
                              <Step
                                label="รับคำสั่งซื้อ"
                                children={<i className="far fa-calendar"></i>}
                              />
                              <Step
                                label="ตรวจสอบแบบ"
                                children={
                                  <i className="fas fa-clipboard-check"></i>
                                }
                              />
                              <Step
                                label="อนุมัติแบบ"
                                children={<i className="fas fa-user-check"></i>}
                              />
                              <Step
                                label="ลงแผนการผลิต"
                                children={<i className="fas fa-users-cog"></i>}
                              />
                            </Stepper>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {timelineForm_4 ? (
                        <div className="card">
                          <div className="card-body">
                            <Stepper
                              style={{
                                fontFamily: "Mitr",
                              }}
                              activeStep={activeCheck_4}
                              styleConfig={{
                                activeBgColor: "#2b7cff",
                                inactiveTextColor: "#fff",
                                completedBgColor: "#2b7cff",
                                //size: "2.5em",
                              }}
                            >
                              <Step
                                label="รับคำสั่งซื้อ"
                                children={<i className="far fa-calendar"></i>}
                              />
                              <Step
                                label="ตรวจสอบแบบ"
                                children={
                                  <i className="fas fa-clipboard-check"></i>
                                }
                              />
                              <Step
                                label="อนุมัติแบบ"
                                children={<i className="fas fa-user-check"></i>}
                              />
                              <Step
                                label="ลงแผนการผลิต"
                                children={<i className="fas fa-users-cog"></i>}
                              />
                              <Step
                                label="ผลิตเสร็จ"
                                children={<i className="fas fa-truck"></i>}
                              />
                            </Stepper>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* End Timeline */}
                      <table className="table table-bordered mt-4">
                        <tr>
                          <td width={"50%"}>วันส่งคำสั่งซื้อ</td>
                          <td>
                            {precut.order_receive_date
                              ? moment(
                                  new Date(precut.order_receive_date)
                                ).format("DD-MM-YYYY")
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>ประเภทงาน</td>
                          <td>{precut.order_type}</td>
                        </tr>
                        {realcustomer ? (
                          <tr>
                            <td>ชื่อโครงการ/ลูกค้า</td>
                            <td>{precut.real_customer_name}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {landNamber ? (
                          <>
                            <tr>
                              <td>หมายเลขแปลง</td>
                              <td>{precut.land_Number}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {homeDesign ? (
                          <>
                            <tr>
                              <td>แบบบ้าน</td>
                              <td>{precut.homeDesign_Name}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {orderUnit ? (
                          <>
                            <tr>
                              <td>จำนวนหลัง</td>
                              <td>{precut.order_Unit}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        {amount ? (
                          <>
                            <tr>
                              <td>จำนวนชุดทั้งหมด</td>
                              <td>{precut.amount}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        {orderSqm ? (
                          <>
                            <tr>
                              <td>จำนวนตารางเมตร</td>
                              <td>{precut.order_Sqm}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {qunmber ? (
                          <tr>
                            <td>เลขที่ใบเสนอราคา</td>
                            <td>{precut.qt_number}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {quotation ? (
                          <>
                            <tr>
                              <td>ต้องการให้ NPI เก็บแบบสำหรับใช้ซ้ำในอนาคต</td>
                              <td>{precut.keep_Quotation}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {type ? (
                          <tr>
                            <td>ประเภทการผลิต</td>
                            <td>{precut.product_type}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {group ? (
                          <tr>
                            <td>ประเภทสินค้า</td>
                            <td>{precut.product_group}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {color ? (
                          <tr>
                            <td>สีของสินค้า</td>
                            <td>{precut.product_color}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {series ? (
                          <tr>
                            <td>รุ่นสินค้า</td>
                            <td>{precut.product_series}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {requestdate ? (
                          <tr>
                            <td>วันที่ต้องการสินค้า</td>
                            <td>
                              {precut.request_date
                                ? moment(new Date(precut.request_date)).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {address ? (
                          <tr>
                            <td>สถานที่จัดส่ง</td>
                            <td>{precut.recieve_address}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {download ? (
                          <tr>
                            <td>ดาวน์โหลดไฟล์</td>
                            <td>
                              <a
                                className="btn btn-primary btn-sm"
                                href={precut.download}
                              >
                                Download
                              </a>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>สถานะงาน</td>
                          <td>{precut.order_status}</td>
                        </tr>
                        {orderPerson ? (
                          <>
                            <tr>
                              <td>ชื่อผู้สั่งซื้อสินค้า</td>
                              <td>{precut.order_Person}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {reject ? (
                          <>
                            <tr>
                              <td>สาเหตุที่ไม่อนุมัติ</td>
                              <td>{precut.reject_comment}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {reason ? (
                          <>
                            <tr>
                              <td>สาเหตุการยกเลิกใบเสนอราคา</td>
                              <td>{precut.reason_npi_cancel}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {request ? (
                          <>
                            <tr>
                              <td>คำร้องขอยกเลิกใบเสนอราคา</td>
                              <td>{precut.text_request_cancel}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {prefsuitno ? (
                          <tr>
                            <td>หมายเลข Prefsuite</td>
                            <td>{precut.prefsuiteNo}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {planningdate ? (
                          <tr>
                            <td>วันกำหนดผลิตเสร็จ</td>
                            <td>{precut.order_planning_date}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {expectdate ? (
                          <tr>
                            <td>วันที่คาดว่าจะผลิตเสร็จ</td>
                            <td>{precut.order_expect_date}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {completedate ? (
                          <tr>
                            <td>วันที่จริงที่ผลิตเสร็จ</td>
                            <td>{precut.order_complete_date}</td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {precut.req_cancel_by ? (
                          <>
                            <tr>
                              <td>ยกเลิกโดย</td>
                              <td>{precut.req_cancel_by}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        {precut.req_cancel_time ? (
                          <>
                            <tr>
                              <td>เวลาที่ยกเลิก</td>
                              <td>
                                {moment(
                                  new Date(precut.req_cancel_time)
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        {precut.req_cancel_reason ? (
                          <>
                            <tr>
                              <td>เหตุผลในการยกเลิก</td>
                              <td>{precut.req_cancel_reason}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        {precut.req_cancel_remark ? (
                          <>
                            <tr>
                              <td>หมายเหตุ</td>
                              <td>{precut.req_cancel_remark}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        {checkApproveDate(precut.approve_master_date) &&
                        ["รอตรวจแบบ", "อนุมัติเตรียมแผนการผลิต"].includes(
                          precut.order_status
                        ) ? (
                          precut.order_status == "อนุมัติเตรียมแผนการผลิต" &&
                          precut.approve_master_date ? (
                            <tr>
                              <td>ยกเลิกคำสั่งผลิต</td>
                              <td>
                                {checkApproveDate(precut.approve_master_date)}
                              </td>
                            </tr>
                          ) : precut.order_status == "รอตรวจแบบ" ? (
                            <tr>
                              <td>ยกเลิกคำสั่งผลิต</td>
                              <td>
                                {checkApproveDate(precut.approve_master_date)}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </table>
                      <div className="float-right mt-2">
                        <Link to="/booking-status" className="btn btn-danger">
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
