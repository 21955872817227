// Constants.js
const prod = {
  url: {
    API_URL:
      "https://precutbooking.windsor.co.th/bookings/laravel_api_auth/public/api/",
    POWER_AUTO: {
      normal:
        "https://prod-62.southeastasia.logic.azure.com:443/workflows/951f0ebbb53945859abba5641b3478a2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=k6WOGpjjBuW7UwCwa9LO6K_EnV1aX8esyZX0xrZ_njM",
      special:
        "https://prod-21.southeastasia.logic.azure.com:443/workflows/07e343faaabe4aa58c7d8ae861bf0855/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=feFgEkpX030inIcY7AAB4_lmLuQLg75swofqUsRfK04",
      confirm:
        "https://prod-60.southeastasia.logic.azure.com:443/workflows/648a6c901790401f9ce6c413ed2b8852/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=wdN0-u0r8meidJrock6RNmSt4nWuUDIM5hAgYx4qmtQ",
    },
  },
};

const dev = {
  url: {
    API_URL:
      "https://precutbooking.windsor.co.th/bookings/laravel_api_auth/public/api/",
    POWER_AUTO: {
      normal:
        "https://prod-62.southeastasia.logic.azure.com:443/workflows/951f0ebbb53945859abba5641b3478a2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=k6WOGpjjBuW7UwCwa9LO6K_EnV1aX8esyZX0xrZ_njM",
      special:
        "https://prod-21.southeastasia.logic.azure.com:443/workflows/07e343faaabe4aa58c7d8ae861bf0855/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=feFgEkpX030inIcY7AAB4_lmLuQLg75swofqUsRfK04",
      confirm:
        "https://prod-60.southeastasia.logic.azure.com:443/workflows/648a6c901790401f9ce6c413ed2b8852/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=wdN0-u0r8meidJrock6RNmSt4nWuUDIM5hAgYx4qmtQ",
    },
  },
};

// const prod = {
//   url: {
//     API_URL:
//       "https://precutbooking.windsor.co.th/bookings_dev/laravel_api_auth/public/api/",
//     POWER_AUTO: {
//       normal:
//         "https://prod-61.southeastasia.logic.azure.com:443/workflows/84bf34ba2d304d7a92b2a0379bd22945/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=DEeW3aE_iCAUybArt5gwZBV5kWmjrx8hrrkKxZBVRJ0",
//       special:
//         "https://prod-13.southeastasia.logic.azure.com:443/workflows/72bf86f544164a4bac087082d03561a2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=g2IWMtf0pm9DOU_rXzUKUcCGuo0dE9HRBbmRcKbSWQo",
//       confirm:
//         "https://prod-18.southeastasia.logic.azure.com:443/workflows/3711e3d74aec4865b53a152c87430b47/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=kFhgpPnOdik8ut2OzqI15LNdpDodW2A4TUuSuJ1Eccs",
//     },
//   },
// };

// const dev = {
//   url: {
//     API_URL:
//       "https://precutbooking.windsor.co.th/bookings_dev/laravel_api_auth/public/api/",
//     POWER_AUTO: {
//       normal:
//         "https://prod-61.southeastasia.logic.azure.com:443/workflows/84bf34ba2d304d7a92b2a0379bd22945/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=DEeW3aE_iCAUybArt5gwZBV5kWmjrx8hrrkKxZBVRJ0",
//       special:
//         "https://prod-13.southeastasia.logic.azure.com:443/workflows/72bf86f544164a4bac087082d03561a2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=g2IWMtf0pm9DOU_rXzUKUcCGuo0dE9HRBbmRcKbSWQo",
//       confirm:
//         "https://prod-18.southeastasia.logic.azure.com:443/workflows/3711e3d74aec4865b53a152c87430b47/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=kFhgpPnOdik8ut2OzqI15LNdpDodW2A4TUuSuJ1Eccs",
//     },
//   },
// };

export const Constants = process.env.NODE_ENV === "development" ? dev : prod;
